define('ember-select/components/select-dropdown-group', ['exports', 'ember-select/templates/components/select-dropdown-group', 'ember-select/components/select-dropdown', 'ember-select/utils/tree'], function (exports, _selectDropdownGroup, _selectDropdown, _tree) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _selectDropdown.default.extend({
    layout: _selectDropdownGroup.default,
    groups: null,
    list: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // Tree built in extended component
      var groups = this.get('list');
      var list = (0, _tree.getDescendents)(groups);

      this.setProperties({ list: list, groups: groups });
    },


    options: Ember.computed('token', 'model.[]', 'values.[]', function () {
      if (this.get('shouldFilter')) {
        this.filterModel();
      }

      return this.get('groups');
    }),

    setVisibility: function setVisibility(list, token) {
      list.filter(function (el) {
        return Ember.isPresent(Ember.get(el, 'parentId'));
      }).filter(function (el) {
        return Ember.get(el, 'name').toLowerCase().indexOf(token) > -1;
      }).forEach(function (el) {
        el.set('isVisible', true);

        // Mark parent visible
        list.filter(function (x) {
          return x.id === Ember.get(el, 'parentId');
        }).shift().set('isVisible', true);
      });
    },
    upDownKeys: function upDownKeys(selected, event) {
      var list = this.get('list').filterBy('isVisible').filter(function (el) {
        return Ember.isPresent(Ember.get(el, 'parentId'));
      });

      this.move(list, selected, event.keyCode);
    }
  });
});