define("ember-inline-edit/components/ember-inline-edit", ["exports", "ember-inline-edit/templates/components/ember-inline-edit"], function (exports, _emberInlineEdit) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _emberInlineEdit.default,
    classNames: ["ember-inline-edit"],
    classNameBindings: ["isEditing:is-editing", "enabled::disabled"],

    isEditing: false,
    isNotEditing: Ember.computed.not("isEditing"),

    enabled: true,
    field: "text",

    value: null,
    previousValue: null,

    placeholder: "Not Provided",
    saveLabel: "Save",
    cancelLabel: "Cancel",
    editLabel: "Edit",

    fieldWidth: null,

    showSaveButton: true,
    showCancelButton: true,

    editorClass: "",
    buttonContainerClass: "",
    editButtonClass: "",
    saveButtonClass: "",
    cancelButtonClass: "",

    didInsertElement: function didInsertElement() {
      this._handleClicks = this._handleClicks.bind(this);
      document.addEventListener("click", this._handleClicks);
    },
    willDestroyElement: function willDestroyElement() {
      document.removeEventListener("click", this._handleClicks);
    },
    _handleClicks: function _handleClicks(ev) {
      var enabled = Ember.get(this, "enabled");
      if (!enabled) return;

      var isEditing = Ember.get(this, "isEditing");
      var clickedInside = this.element.contains(ev.target);

      if (clickedInside && !isEditing) {
        if (Ember.get(this, "showEditButton")) {
          return;
        }

        this._setFieldWidth();
        this.send("startEditing", ev);
      } else if (!clickedInside && isEditing) {
        this.send("cancel");
      }
    },
    _setFieldWidth: function _setFieldWidth() {
      var _this = this;

      var _element$getBoundingC = this.element.getBoundingClientRect(),
          width = _element$getBoundingC.width;

      Ember.run(this, function () {
        Ember.set(_this, "fieldWidth", Ember.String.htmlSafe("width: " + (width + 2) + "px"));
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, "enabled") === false) {
        this.send("cancel");
      }
    },


    actions: {
      save: function save() {
        var _this2 = this;

        this.sendAction("onSave", this.get("value"));

        Ember.run(this, function () {
          Ember.set(_this2, "isEditing", false);
        });
      },
      startEditing: function startEditing(e) {
        var _this3 = this;

        e.stopPropagation();
        this.sendAction("onEdit");

        Ember.run(this, function () {
          Ember.set(_this3, "previousValue", _this3.get("value"));
          Ember.set(_this3, "isEditing", true);
        });
      },
      cancel: function cancel() {
        var _this4 = this;

        this.sendAction("onCancel");

        Ember.run(this, function () {
          Ember.set(_this4, "value", _this4.get("previousValue"));
          Ember.set(_this4, "isEditing", false);
        });
      }
    }
  });
});