define("ember-inline-edit/components/ember-inline-editable", ["exports", "ember-inline-edit/templates/components/ember-inline-editable"], function (exports, _emberInlineEditable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _emberInlineEditable.default,

    classNames: ["ember-inline-editable"],
    classNameBindings: ["isVisible:is-visible:is-hidden", "showEditButton:is-not-clickable"],
    valueIsEmpty: Ember.computed.empty("value")
  });
});