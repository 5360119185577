define("ember-inline-edit/components/ember-inline-editor", ["exports", "ember-inline-edit/templates/components/ember-inline-editor"], function (exports, _emberInlineEditor) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var isInputField = function isInputField(el) {
    var tagName = el.tagName;


    if (!tagName) {
      return false;
    }

    return ["input", "textarea", "select"].includes(tagName.toLowerCase());
  };

  exports.default = Ember.Component.extend({
    layout: _emberInlineEditor.default,
    classNameBindings: ["isVisible:is-visible:is-hidden"],

    textFields: ["search", "url", "text", "phone", "email", "number"],
    textAreaFields: ["textarea"],

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.run.scheduleOnce("afterRender", this.focusOnInput.bind(this));
    },
    focusOnInput: function focusOnInput() {
      var children = [].concat(_toConsumableArray(this.element.children));

      children.forEach(function (child) {
        if (isInputField(child)) child.focus();
      });
    },
    keyUp: function keyUp(ev) {
      var field = Ember.get(this, "field");
      var textAreaFields = Ember.get(this, "textAreaFields");

      var keyCode = ev.keyCode;


      var isEnter = keyCode === 13;
      var isEsc = keyCode === 27;

      if (isEnter && !textAreaFields.includes(field)) {
        this.sendAction("on-save");
      } else if (isEsc) {
        this.sendAction("on-cancel");
      }
    }
  });
});