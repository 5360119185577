define('ember-tri-state/components/tri-noop', ['exports', 'ember-tri-state/templates/components/tri-noop'], function (exports, _triNoop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _triNoop.default,
    tagName: ''
  });
});