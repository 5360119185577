define('ember-select/utils/tree', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.buildTree = buildTree;
  exports.buildFlatList = buildFlatList;
  exports.getDescendents = getDescendents;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  /* Build a tree (nested objects) from a plain array
   * using `id` and `parentId` as references for the
   * relationships. The `name` property is expected
   * for rendering. Optionally, `valueKey` can be
   * passed for `id` and `labelKey` for `name`.
   * If the model is flat, it will return a list.
   */
  function buildTree(model, options) {
    var tree = {};
    var roots = Ember.A();

    if (Ember.isEmpty(model)) {
      return roots;
    }

    var element = model[0] || Ember.get(model, 'firstObject');
    if ((typeof element === 'undefined' ? 'undefined' : _typeof(element)) !== 'object') {
      // Not a model of objects, hence it should be a flat list
      return buildFlatList(model);
    }

    // Add all nodes to tree
    model.forEach(function (node) {
      var child = {
        content: node,
        children: Ember.A(),
        isSelected: false,
        isVisible: true
      };

      // Alternative name for `id`
      if (options.valueKey) {
        child.id = Ember.get(node, options.valueKey);
      }

      // Alternative name for `name`
      if (options.labelKey) {
        child.name = Ember.get(node, options.labelKey);
      }

      // Decide if node is expanded
      if (Ember.isPresent(options.isExpanded)) {
        child.isExpanded = options.isExpanded;
      }

      // Proxy options to keep model intact
      tree[Ember.get(child, 'id')] = Ember.ObjectProxy.create(child);
    });

    // Connect all children to their parent
    model.forEach(function (node) {
      var child = tree[Ember.get(node, options.valueKey || 'id')];
      var parent = Ember.get(node, 'parentId');

      if (Ember.isEmpty(parent)) {
        roots.push(child);
      } else {
        tree[parent].children.push(child);
      }
    });

    return roots;
  }

  // Builds a list of proxies from a model of values
  function buildFlatList(model) {
    var list = model.map(function (node) {
      return Ember.ObjectProxy.create({
        content: node,
        id: node,
        name: node,
        isSelected: false,
        isVisible: true
      });
    });

    return Ember.A(list);
  }

  function getDescendents(tree) {
    var descendents = Ember.A();

    tree.forEach(function (node) {
      descendents.pushObject(node);
      descendents.pushObjects(getDescendents(node.children));
    });

    return descendents;
  }
});