define('ember-select/components/select-dropdown-option', ['exports', 'ember-select/templates/components/select-dropdown-option'], function (exports, _selectDropdownOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _selectDropdownOption.default,
    classNames: ['es-option'],
    classNameBindings: ['model.isSelected:es-highlight'],

    click: function click() {
      this.attrs.select(this.get('model'));
    },
    mouseEnter: function mouseEnter() {
      this.attrs.hover(this.get('model'));
    }
  });
});